<template>
  <el-dialog :title="ModalTitle" v-model="shouldShow">
    <form>
      <el-row type="flex">
        <el-col> </el-col>
      </el-row>
      <el-row type="flex" justify="space-between" align="center">
        <h4>Pode emitir NF:</h4>
        <el-switch
          v-model="firm_.could_emit_invoices"
          active-text="Sim"
          inactive-text="Não"
        >
        </el-switch>
      </el-row>
      <el-row type="flex" justify="start">
        <h4>Identificação:</h4>
      </el-row>
      <base-input
        label="Nome:"
        hint="Razão social da empresa"
        v-model="firm_.name"
      >
      </base-input>
      <base-input
        label="CNPJ:"
        hint="99.999.999/0001-99"
        mask="##!.###!.###!/####!-##"
        v-model="firm_.cnpj"
      >
      </base-input>
      <base-input label="Email:" hint="email@exemplo.com" v-model="firm_.email">
      </base-input>

      <base-input label="Telefone:" hint="" v-model="firm_.phone"> </base-input>

      <base-input
        label="Código da empresa:"
        hint="Código na TecnoSpeed"
        v-model="firm_.firm_id"
        mask="###########"
      >
      </base-input>

      <el-row type="flex" justify="start">
        <h4>Limite de Faturamento:</h4>
      </el-row>
      <base-input
        label="Mensal:"
        hint="Deixe 0 para ilimitado"
        type="money"
        v-model="firm_.monthly_billing_limit"
      >
      </base-input>
      <base-input
        label="Anual:"
        type="money"
        hint="Deixe 0 para ilimitado"
        v-model="firm_.yearly_billing_limit"
      >
      </base-input>
      <el-row type="flex" align="center" justify="space-between">
        <h4>Tipo da Empresa:</h4>
      </el-row>
      <el-select v-model="firm_.type">
        <el-option
          v-for="v in FirmTypes"
          :label="v.label"
          :value="v.value"
          :key="v"
        ></el-option>
      </el-select>
      <el-row type="flex" align="center" justify="space-between">
        <h4>Endereço:</h4>
      </el-row>
      <el-row :gutter="8">
        <el-col :md="18">
          <base-input v-model="SafeAddr.street" label="Rua:"> </base-input>
        </el-col>
        <el-col :md="6">
          <base-input v-model="SafeAddr.number" label="N:" hint="0000">
          </base-input>
        </el-col>
      </el-row>
      <el-row :gutter="8">
        <el-col :md="14">
          <base-input v-model="SafeAddr.place" label="Bairro:"> </base-input>
        </el-col>
        <el-col :md="10">
          <base-input
            v-model="SafeAddr.postal_code"
            label="CEP:"
            mask="##!.###!-###"
            hint="00.0000-000"
          >
          </base-input>
        </el-col>
      </el-row>
      <el-row :gutter="8">
        <el-col :md="14">
          <base-input v-model="SafeAddr.city" label="Cidade:"> </base-input>
        </el-col>
        <el-col :md="10">
          <el-select
            v-model="SafeAddr.state"
            placeholder="Estado:"
            size="medium"
          >
            <el-option
              v-for="item in BrazilStates"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row type="flex" justify="start">
        <h4>Integração com Webmania:</h4>
      </el-row>
      <base-input
        label="ID da empresa"
        hint="Adicione aqui o código a consumer id da webmania"
        v-model="firm_.consumer_key"
      >
      </base-input>
      <base-input
        label="Chave da empresa"
        hint="Adicione aqui o código a consumer key da webmania"
        v-model="firm_.consumer_secret"
      >
      </base-input>
      <base-input
        label="Serie pra emissão da NF"
        hint="Qual serie o sistema deve utilizar?"
        v-model="firm_.serie"
      >
      </base-input>
      <el-row type="flex" justify="space-between">
        <h4>Empresa no Simples Nacional:</h4>
        <el-switch
          v-model="firm_.use_simple_tax"
          active-text="Sim"
          inactive-text="Não"
          :active-value="true"
          :inactive-value="false"
        >
        </el-switch>
      </el-row>
    </form>
    <template #footer>
      <el-row justify="space-between" type="flex">
        <el-button type="text" class="button" @click="shouldShow = false"
          >Cancelar</el-button
        >
        <el-button
          type="text"
          :isLoading="isLoadingSave"
          class="button"
          @click="save"
          >Salvar</el-button
        >
      </el-row>
    </template>
  </el-dialog>
</template>
<script>
import BaseInput from "../../components/BaseInput.vue";
import { ElNotification } from "element-plus";

export default {
  props: ["firm", "showModal"],
  components: { BaseInput },
  emits: ["close-modal", "should-update"],
  data() {
    return {
      firm_: { ...this.firm, addr: this.firm?.addr || {} },
      isLoadingSave: false,
      systemActions: [],
    };
  },
  watch: {
    firm(v) {
      this.firm_ = { ...v, addr: v?.addr || {} };
    },
  },
  computed: {
    BrazilStates() {
      return this.$store.state.constants.states;
    },
    ModalTitle() {
      return this.firm ? "Editar empresa" : "Cadastrar empresa";
    },
    SafeAddr() {
      return this?.firm_?.addr || {};
    },
    shouldShow: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit("close-modal");
      },
    },
    FirmTypes() {
      return [
        {
          label: "Desconhecido",
          value: "unknown",
        },
        {
          label: "Açougue",
          value: "shop",
        },
      ];
    },
  },
  methods: {
    save() {
      this.isLoadingSave = true;
      return this.firm ? this.updateFirm() : this.createFirm();
    },
    createFirm() {
      fetch(`${this.$store.state.apiUrl}firms`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(this.firm_),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then(async (response) => {
          if (response.status == 200) return await response.json();
          else throw await response.json();
        })
        .then(
          (e) => {
            this.$emit("should-update");
            this.shouldShow = false;
            this.firm_ = {};
            ElNotification.success({
              title: e.message,
              position: "bottom-right",
            });
            this.$emit("close-modal");
          },
          (e) => {
            ElNotification.error({
              title: "Ocorreu um erro ao cadastrar empresa",
              message: e.message,
              position: "bottom-right",
            });
          }
        )
        .finally(() => {
          this.isLoadingSave = false;
        });
    },
    updateFirm() {
      fetch(`${this.$store.state.apiUrl}firms/${this.firm_.uid}`, {
        credentials: "include",
        method: "PUT",
        body: JSON.stringify({ ...this.firm_ }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then(async (response) => {
          if (response.status == 200) return await response.json();
          else throw await response.json();
        })
        .then(
          (e) => {
            this.$emit("should-update");
            ElNotification.success({
              title: e.message,
              position: "bottom-right",
            });
            this.$emit("close-modal");
          },
          (e) => {
            ElNotification.error({
              title: "Ocorreu um erro ao atualizar empresa",
              message: e.message,
              position: "bottom-right",
            });
          }
        )
        .finally(() => {
          this.isLoadingSave = false;
          this.shouldShow = false;
        });
    },
  },
  name: "FirmModal",
};
</script>
<style>
.el-button {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.el-select {
  max-height: 36px;
  margin-top: 5px !important;
  display: block !important;
}
</style>

<template>
  <el-card v-loading="isLoading" shadow="always">
    <template #header>
      <el-row type="flex" justify="space-between" align="center">
        <h4>Minhas Empresas</h4>
        <el-col :md="15">
          <el-row type="flex" justify="end">
            <el-button
              type="primary"
              icon="el-icon-plus"
              size="medium"
              @click="openFirmModal(null)"
            ></el-button>
          </el-row>
        </el-col>
      </el-row>
    </template>
    <el-table
      stripe
      :cell-style="() => 'text-align:center;'"
      :data="firms"
      style="width: 100%; z-index: 0"
    >
      <el-table-column prop="name" label="nome"> </el-table-column>
      <el-table-column label="faturado no mês">
        <template #default="b">
          {{ formatCurrency(b.row.monthly_billing_realized) }}
        </template>
      </el-table-column>
      <el-table-column label="limite de faturamento">
        <template #default="b">
          {{ formatCurrency(b.row.monthly_billing_limit) }}
        </template>
      </el-table-column>
      <el-table-column label="ações" :width="100">
        <template #default="cat">
          <el-row type="flex" justify="end">
            <el-button-group>
              <el-button
                onlyIcon
                type="primary"
                circle
                @click="openFirmModal(cat.row)"
              >
                <i class="el-icon-edit"></i
              ></el-button>
              <el-popconfirm
                @confirm="deleteFirm(cat.row)"
                title="
              Remover empresa?
            "
                ><template #reference>
                  <el-button onlyIcon type="danger" circle>
                    <i class="el-icon-delete"></i></el-button></template
              ></el-popconfirm>
            </el-button-group>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <firm-modal
      :showModal="showFirmModal"
      :firm="firm"
      @close-modal="showFirmModal = false"
      @should-update="fetchFirms"
    ></firm-modal>
  </el-card>
</template>

<script>
import FirmModal from "./modals/FirmModal.vue";
export default {
  name: "FirmsPage",
  components: { FirmModal },
  data: () => ({
    hasError: false,
    isLoading: true,
    firms: null,
    firm: null,
    currencyFormatter: new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }),
    showFirmModal: false,
  }),
  mounted() {
    this.fetchFirms();
  },
  methods: {
    formatCurrency(c) {
      return c ? this.currencyFormatter.format(c) : "R$ 0,00";
    },
    deleteFirm(product) {
      fetch(`${this.$store.state.apiUrl}firms/${product.uid}`, {
        credentials: "include",
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else throw response.json();
        })
        .then(
          () => {
            this.fetchFirms();
            this.$notify({
              title: "Empresa removida com sucesso.",
              type: "success",
              position: "bottom-right",
            });
          },
          (e) => {
            e.then((e) =>
              this.$notify({
                title: "Não foi possível remover a empresa",
                type: "error",
                message: e.message,
                position: "bottom-right",
              })
            );
          }
        );
    },
    ProductPrice(p, type) {
      if (type === "min")
        return `M: R$ ${p?.price?.male_min || "0,00"} F: R$ ${
          p?.price?.female_min || "0,00"
        }`;
      else
        return `M: R$ ${p?.price?.male_std || "0,00"} F: R$ ${
          p?.price?.female_std || "0,00"
        }`;
    },
    openFirmModal(e) {
      (this.firm = e), (this.showFirmModal = true);
    },
    fetchFirms() {
      fetch(`${this.$store.state.apiUrl}firms`, {
        credentials: "include",
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else return response.text();
        })
        .then((json) => (this.firms = json))
        .catch(() => (this.hasError = true))
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>
<style scoped>
.el-card {
  width: 100%;
}
</style>